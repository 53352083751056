<template>
	<div class="row">
		<div class="form-group col-md-8">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input v-model="text" @keypress.enter="mtd_executeFilter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button @click="mtd_executeFilter" class="btn btn-primary btn-sm">Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			text: null,
		};
	},
	created() {
		this.mtd_executeFilter();
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit']),

		mtd_executeFilter(type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			const params = {
				url: `${this.url_api}/enterprises/index?page=${pagination}&text=${this.text == null ? '' : this.text}`,
				mutation: 'ENTERPRISES_INDEX__SET_ALL',
			};
			this.get(params);
		},
	},
};
</script>
