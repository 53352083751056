<template>
	<div class="modal-body">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'create'" class="row">
			<div class="col-md-12">
				<!--NAVS	-->
				<nav>
					<div class="nav nav-tabs" id="nav-tab" role="tablist">
						<a class="nav-link nav-link-step active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"
							>Datos del cliente</a
						>
						<a class="nav-link nav-link-step" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
							Dirección<i
								class="fas fa-plus-circle text-primary cursor-pointer ml-2"
								data-toggle="collapse"
								data-target="#formCreateAddresss"
								aria-expanded="false"
								aria-controls="formCreateAddresss"></i>
						</a>
					</div>
				</nav>
				<div class="tab-content" id="nav-tabContent">
					<!-- DATOS DEL CLIENTE -->
					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
						<div class="row mt-3">
							<!-- NUMERO DE DOC. -->
							<div class="form-group col-md-12">
								<label>Número de Doc.</label>
								<div class="input-group">
									<input
										v-model="filter.document_number"
										@keypress.enter="mtd_findEnterprise"
										@change="mtd_changeDocumentNumber"
										type="text"
										class="form-control form-control-sm"
										name="document_number"
										v-validate="'required'"
										data-vv-as="'Número de Doc.'" />
									<button @click="mtd_findEnterprise" class="btn btn-xs btn-primary"><i class="fas fa-search"></i> SUNAT</button>
									<span v-show="errors.has('document_number')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('document_number') }} </span>
								</div>
							</div>
							<!-- RAZON SOCIAL -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="social_reason">Razon Social</label>
								<input
									v-model="enterprise.company.social_reason"
									name="social_reason"
									type="text"
									class="form-control form-control-sm"
									v-validate="'required'"
									data-vv-as="'Razon social'" />
								<span v-show="errors.has('social_reason')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('social_reason') }} </span>
								<ErrorBackendComponent :errorsBackend="errorsBackend" name="social_reason" />
							</div>
							<!-- PARTIDA ELECTRINICA -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="departure">N° Partida Electrónica</label>
								<input v-model="enterprise.company.departure" name="departure" type="text" class="form-control form-control-sm" />
							</div>
							<!-- DNI REPRESENTANTE LEGAL -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="dni_legal_representative">N° DNI Representante legal</label>
								<input v-model="enterprise.company.dni_legal_representative" name="dni_legal_representative" type="text" class="form-control form-control-sm" />
							</div>
							<!-- REPRESENTANTE LEGAL -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="legal_representative">Representante legal</label>
								<input v-model="enterprise.company.legal_representative" name="legal_representative" type="text" class="form-control form-control-sm" />
							</div>
						</div>
					</div>
					<!-- DIRECCION -->
					<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
						<!-- FORMULARIO DE CREACION DE DIRECCION -->
						<div class="collapse pt-3" id="formCreateAddresss">
							<div class="row">
								<div class="form-group col-md-12">
									<label>Nueva dirección</label>
									<div class="input-group">
										<input v-model.trim="form_addresses.address" type="text" class="form-control text-uppercase" />
										<button @click="mtd_addAddress" class="btn btn-success">Agregar</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<!-- TABLA DE DIRECCIONES -->
							<template v-if="enterprise.company.addresses.length">
								<div class="col-md-12">
									<div class="table-responsive">
										<table class="table table-sm table-hover table-bordered font-inter">
											<thead class="thead-light">
												<tr>
													<th width="30px">#</th>
													<th>Dirección</th>
													<th class="text-center">Quitar</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(address, i) in enterprise.company.addresses" :key="i">
													<td>{{ i + 1 }}</td>
													<td>{{ address }}</td>
													<td class="text-center">
														<button @click="mtd_removeAddress(address)" class="btn btn-xs btn-danger"><i class="fas fa-trash"></i></button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</template>
							<template v-else>
								<div class="col-12">
									<p class="text-center">Sin direcciones</p>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- EDITAR -->
		<div v-else-if="propInfo.data.method == 'edit'" class="row">
			<div class="col-md-12">
				<!--NAVS	-->
				<nav>
					<div class="nav nav-tabs" id="nav-tab" role="tablist">
						<a class="nav-link nav-link-step active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"
							>Datos del cliente</a
						>
						<a class="nav-link nav-link-step" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
							Dirección
							<i
								class="fas fa-plus-circle text-primary cursor-pointer ml-2"
								data-toggle="collapse"
								data-target="#formCreateAddresss"
								aria-expanded="false"
								aria-controls="formCreateAddresss"></i>
						</a>
					</div>
				</nav>
				<div class="tab-content" id="nav-tabContent">
					<!-- DATOS DEL CLIENTE -->
					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
						<div class="row mt-3">
							<!-- NUMERO DE RUC. -->
							<div class="col-md-6">
								<label>Número de Ruc.</label>
								<p>{{ enterprise.company.document_number }}</p>
							</div>
							<!-- RAZON SOCIAL -->
							<div class="col-md-12">
								<label class="col-form-label label-modal" for="social_reason">Razon Social</label>
								<p>{{ enterprise.company.social_reason }}</p>
							</div>
							<!-- PARTIDA ELECTRINICA -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="departure">N° Partida Electrónica</label>
								<input v-model="enterprise.company.departure" name="departure" type="text" class="form-control form-control-sm" />
							</div>
							<!-- DNI REPRESENTANTE LEGAL -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="dni_legal_representative">N° DNI Representante legal</label>
								<input v-model="enterprise.company.dni_legal_representative" name="dni_legal_representative" type="text" class="form-control form-control-sm" />
							</div>
							<!-- REPRESENTANTE LEGAL -->
							<div class="form-group col-md-12">
								<label class="col-form-label label-modal" for="legal_representative">Representante legal</label>
								<input v-model="enterprise.company.legal_representative" name="legal_representative" type="text" class="form-control form-control-sm" />
							</div>
						</div>
					</div>
					<!-- DIRECCION -->
					<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
						<!-- FORMULARIO DE CREACION DE DIRECCION -->
						<div class="collapse pt-3" id="formCreateAddresss">
							<div class="row">
								<div class="form-group col-md-12">
									<label>Nueva dirección</label>
									<div class="input-group">
										<input v-model.trim="form_addresses.address" type="text" class="form-control text-uppercase" />
										<button @click="mtd_addAddress" class="btn btn-success">Agregar</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<!-- TABLA DE DIRECCIONES -->
							<template v-if="enterprise.company.addresses.length">
								<div class="col-md-12">
									<div class="table-responsive mt-3">
										<table class="table table-hover table-sm table-bordered font-inter">
											<thead class="thead-light">
												<tr>
													<th width="130px">#</th>
													<th>Dirección</th>
													<th>Quitar</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(address, i) in enterprise.company.addresses" :key="i">
													<td>{{ i + 1 }}</td>
													<td>{{ address }}</td>
													<td class="text-center">
														<button @click="mtd_removeAddress(address)" class="btn btn-xs btn-danger"><i class="fas fa-trash"></i></button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</template>
							<template v-else>
								<div class="col-md-12 text-center">
									<p>Sin direcciones</p>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			filter: {
				document_number: null,
			},
			enterprise: {
				id: null,
				company: {
					id: null,
					document_number: null,
					social_reason: null,
					addresses: [],
					legal_representative: '',
					dni_legal_representative: '',
					departure: '',
				},
			},
			form_addresses: {
				address: null,
			},

			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_findEnterprise() {
			if (this.filter.document_number != null && this.filter.document_number.length !== 11) return window.message('info', 'El número de doc. debe tener 11 dígitos');

			this.get({
				url: `${this.url_api}/enterprise/find?document_number=${this.filter.document_number}`,
			})
				.then(res => {
					if (res.state == 'success') {
						this.mtd_setCompany(res.company);
					} else {
						window.message('error', 'No se encuentra a la empresa');
					}
				})
				.catch(err => console.log(err));
		},
		mtd_setCompany(company) {
			if (company.id != null) this.enterprise.company.id = company.id;
			if (company.document_number != null) this.enterprise.company.document_number = company.document_number;
			if (company.social_reason != null) this.enterprise.company.social_reason = company.social_reason;
			if (company.addresses != null) this.enterprise.company.addresses = company.addresses;
			this.enterprise.company.legal_representative = company.legal_representative;
			this.enterprise.company.dni_legal_representative = company.dni_legal_representative;
			this.enterprise.company.departure = company.departure;
		},
		mtd_changeDocumentNumber() {
			this.enterprise.company.document_number = this.filter.document_number;
		},
		mtd_onCreate() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_onEdit() {
			this.mtd_editEnterprise();
		},
		mtd_editEnterprise() {
			this.get({ url: `${this.url_api}/enterprise/${this.propInfo.data.row.id}/edit` })
				.then(res => {
					if (res.state == 'success') {
						this.mtd_setEnterprise(res.enterprise);
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_setEnterprise(enterprise) {
			if (enterprise.id != null) this.enterprise.id = enterprise.id;
			this.mtd_setCompany(enterprise.company);
		},
		mtd_store() {
			this.post({
				url: `${this.url_api}/enterprise/store`,
				params: { enterprise: this.enterprise },
			})
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { enterprise: res.enterprise }, action: 'store', modal_id: this.modal_id });
						window.message('success', 'Registro exitoso');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_update() {
			this.update({
				url: `${this.url_api}/enterprise/${this.enterprise.id}/update`,
				params: { enterprise: this.enterprise },
			})
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { enterprise: res.enterprise }, action: 'update', modal_id: this.modal_id });
						window.message('success', 'Actualización exitosa');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		// FORMULARIO DE DIRECCIONES
		mtd_addAddress() {
			if (this.form_addresses.address != null) {
				this.enterprise.company.addresses.push(this.form_addresses.address.toUpperCase());
				this.form_addresses.address = null;
			}
		},
		mtd_removeAddress(address) {
			this.enterprise.company.addresses = this.enterprise.company.addresses.filter(el => el != address);
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close() {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
